import React from 'react';
import gsap from "gsap";

class LazyBackground extends React.Component {

  state = { src: null };

  componentDidMount() {

    const imageLoader = new Image();

    imageLoader.onload = () => {

      this.setState({ src: this.props.src }, () => {

        gsap.to('.' + this.props.className.replace(/ /g, "."), 0.05, { opacity: 1 });

      });

    };

    imageLoader.src = this.props.src;

  }

  render() {

    return (
      <>

        { !this.state.src ? null : (
          <div
            style={{ backgroundImage: 'url(' + this.state.src + ')' }}
            className={ this.props.className }
          ></div>
        ) }

      </>
    )

  }

}

export default LazyBackground;
