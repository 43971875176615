import React from 'react';
import Logo from './img/ASJ.png';
import { Document, Page, Text, Image, View, Font } from '@react-pdf/renderer';
import font from "./font/HelveticaNeueLTStd-Md.ttf"

class Pdf extends React.Component {

  render() {

    Font.register({ family: 'Helvetica Neue LT Std', src: font });

    return (
      <Document>
        <Page
          size="A4"
          style={{
            fontFamily: 'Helvetica Neue LT Std',
            fontSize: '14pt',
            lineHeight: 1,
            padding: '14pt',
            backgroundColor: '#1D1D1B',
            color: '#FFFFFF' }}
        >
          <View style={{
            paddingBottom: '14pt',
            borderBottom: '1pt solid #FFFFFF',
            marginBottom: '12pt'
          }}>
            <Image src={ Logo } style={{ width: '100%', height: 'auto' }} />
          </View>
          <View style={{
            paddingBottom: '14pt',
            borderBottom: '1pt solid #FFFFFF',
            marginBottom: '12pt'
          }}>
            <Text>{ this.props.item.id }</Text>
            <Text>{ this.props.item.text.replace(/(\r\n|\n|\r)/gm," ") }</Text>
            {!this.props.item.pdfInfo ? null : (
              <>
                <Text>{ '\n' }</Text>
                {this.props.item.pdfInfo.map((info, i) => {
                  return(
                    <View
                      key={ 'backgroundInfo' + i }
                      style={{ flexDirection: 'row' }}
                    >
                      <Text style={{ width: '25%' }}>{ info.key }</Text>
                      <Text style={{ width: '75%' }}>{ info.value }</Text>
                    </View>
                  )
                }) }
              </>
            ) }
          </View>
          {!this.props.item.overlays.length > 0 ? null : (
            <>
              {this.props.item.overlays.map((item, i) => {
                return(
                  <View
                    key={ 'overlay' + i }
                    style={{
                      paddingBottom: '14pt',
                      borderBottom: '1pt solid #FFFFFF',
                      marginBottom: '14pt'
                  }}>
                    <Text>{ item.id }</Text>
                    <Text>{ item.text.replace(/(\r\n|\n|\r)/gm," ") }</Text>
                    {!item.pdfInfo ? null : (
                      <>
                        <Text>{ '\n' }</Text>
                        {item.pdfInfo.map((info, i) => {
                          return(
                            <View
                              key={ 'overlayInfo' + i }
                              style={{ flexDirection: 'row' }}
                            >
                              <Text style={{ width: '25%' }}>{ info.key }</Text>
                              <Text style={{ width: '75%' }}>{ info.value }</Text>
                            </View>
                          )
                        }) }
                      </>
                    ) }
                  </View>
                )
              }) }
            </>
          ) }
          <View style={{
            textTransform: 'uppercase',
            position: 'absolute',
            left: '14pt',
            bottom: '14pt' }}
          >
            <Text>
              Archivio Slam Jam{ '\n' }
              Via Francesco Luigi Ferrari, 37/A 44122 – Ferrara – Italy{ '\n' }
              archivio@slamjam.com - archivio.slamjam.com
            </Text>
          </View>
          <View style={{
            textTransform: 'uppercase',
            position: 'absolute',
            right: '14pt',
            bottom: '14pt' }}
          >
            <Text>
              { this.props.id }
            </Text>
          </View>
        </Page>
      </Document>
    )

  }

}

export default Pdf;
