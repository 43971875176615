import React from 'react';
import LazyBackground from './lazyBackground';
import LazyImage from './lazyImage';
import Pdf from './pdf';
import CookieConsent from "react-cookie-consent";
import { PDFDownloadLink } from '@react-pdf/renderer';
import html2canvas from 'html2canvas';
import gsap from "gsap";
import Draggable from "gsap/Draggable";
import InertiaPlugin from "gsap/InertiaPlugin";
import InfiniteScroll from 'react-infinite-scroller';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import TextareaAutosize from 'react-textarea-autosize';
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import MarqueeText from 'react-marquee-text-component';
import ReactGA from 'react-ga';
import { Textfit } from 'react-textfit';
import {createClient} from '@sanity/client'

import Logo from './img/ASJ.png';
import LogoShadow from './img/ASJ-shadow.png';

gsap.registerPlugin(Draggable, InertiaPlugin);

const client = createClient({
  projectId: '2vvqnusb',
  dataset: 'production',
  apiVersion: '2021-03-25',
  useCdn: true
});

class App extends React.Component {

  constructor(props) {

    super(props);

    this.randomMax =  601;
    this.randomStart = this.randomIntFromInterval(21, this.randomMax);

    this.state = {
      content: null,
      startAudio: false,
      audio: 0,
      isPlaying: false,
      rangeFetch: [this.randomStart, this.randomStart+20],
      randomLap: false,
      hasFetch: true,
      modal: '',
      form: {
        name: '',
        email: '',
        why: 'Voglio visitare l\'archivio',
        profession: '',
        note: '',
        policy: false,
        sent: false,
        requested: false
      },
      newsOpened: null
    };

    this.pdf = [];
    this.modal = '';

    this.dataFetch = this.dataFetch.bind(this);

    this.scrolled = false;

  }

  componentDidMount() {

    ReactGA.initialize('UA-40723232-7');
    ReactGA.pageview(window.location.pathname + window.location.search);

    this.dataFetch(0);

    window.addEventListener('scroll', () => {

      if (!this.state.modal) {

        this.scroll();

      }

    });

  }

  randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  

  scroll() {

    if (document.documentElement.scrollTop > 1) {

      gsap.set('header.blend', { opacity: 1 });
      gsap.set('header.noBlend', { opacity: 0 });

      this.acceptCookies();

    } else {

      gsap.set('header.noBlend', { opacity: 1 });
      gsap.set('header.blend', { opacity: 0 });

    }

  }

  dataFetch(type = 1) {

    var query;

    if (type === 0) {

      query = "*[_type == 'home'][0]{ 'news': *[_type == 'news'] | order(date desc) { date, title, 'content': content[]{ text, 'file': asset->url, _type } }, manifest, contact, audio[]{ 'url': file.asset->url, textTicker }, 'pdf': count(*[_type == 'item' && (length(overlays) > 0 || defined(url)) && _id in ^.items[]._ref]), 'itemsLen': count(items), 'items': items[" + this.state.rangeFetch[0] + ".." + this.state.rangeFetch[1] + "]->{ id, image{ 'url': asset->url }, video{ 'url': asset->url }, width, height, url, 'overlays': overlays[]{ id, image{ 'url':asset->url }, video{ 'url': asset->url }, width, x, y, widthMobile, xMobile, yMobile, text, textColor, textPaddingHorizontal, textPaddingVertical, pdfInfo }, text, pdfInfo } }";

    } else {

      query = "*[_type == 'home'][0]{ 'items': items[" + this.state.rangeFetch[0] + ".." + this.state.rangeFetch[1] + "]->{ id, image{ 'url':asset->url, }, video{ 'url': asset->url }, width, height, url, overlays[]{ id, image{ 'url': asset->url }, video{ 'url': asset->url }, width, x, y, widthMobile, xMobile, yMobile, text, textColor, textPaddingHorizontal, textPaddingVertical, pdfInfo }, text, pdfInfo } }";

    }

    client.fetch(query).then(content => {

      this.shuffle(content.items);

      if (type === 1) {

        if (content.items.length > 0) {

          var oldContent = this.state.content;
          oldContent.items = oldContent.items.concat(content.items);
          content = oldContent;

        } else {

          return;

        }

      } else {

        this.shuffle(content.audio);

      }

      this.setState({ content: content }, () => {

        if (type === 0) {

          var audioEl = document.getElementById("audio");

          audioEl.addEventListener('ended', () => {

            var newAudio;

            if (this.state.audio+1 === this.state.content.audio.length) {

              newAudio = 0;

            } else {

              newAudio = this.state.audio+1;

            }

            this.setState({ audio: newAudio }, () => {

              this.toggleAudio(true);

            });

          });

        }

      });

      this.setState({ rangeFetch: [this.state.rangeFetch[0]+21, ((this.state.rangeFetch[1]+20 > this.randomStart && !this.state.hasFetch) ? this.randomStart : this.state.rangeFetch[1]+20) ] });

      if (this.state.rangeFetch[0] > this.randomMax) this.setState({ rangeFetch: [0, 20], hasFetch: false });

    });

  }


  toggleAudio(load = false) {

    var audioEl = document.getElementById("audio");

    if (this.state.isPlaying) {

      audioEl.pause();

      this.setState({ isPlaying: false }, () => {

        if (load === true) {

          audioEl.load();

          this.toggleAudio();
        }

      });

    } else {

      audioEl.play();

      this.setState({ isPlaying: true, startAudio: true });

    }

  }

  showOverlay(id) {

    if (this.state.startAudio === false) { this.toggleAudio(); }

    var content = this.state.content;
    var url = false;

    if (content.items[id].overlays) {

      for (var i = 0; i < content.items[id].overlays.length; i++) {

        if (content.items[id].overlays[i].visible) {

          continue;

        } else {

          url = true;

          content.items[id].overlays[i].visible = true;

          break;

        }

      }

    }

    if (!content.items[id].visibleURL && content.items[id].url && url === false) {

      content.items[id].visibleURL = true;

      this.setState({ content: content });

    } else if (url === false) {

      content.items[id].visibleCTA = true;
      content.items[id].ended = true;

      this.setState({ content: content }, () => { this.leaveCursor(); });

    } else {

      this.setState({ content: content }, () => {

        Draggable.create("#image" + id + '_' + i, {
          type: "x,y",
          zIndexBoost: false,
          autoScroll: 1
        });

      });

    }

  }

  mouseMove(event) {

    gsap.set('#plus', { y: event.clientY, x: event.clientX });

  }

  enterCursor() {

    gsap.set('body', { cursor: 'none' });
    gsap.set('#plus', { opacity: '1' });

  }

  leaveCursor() {

    gsap.set('body', { cursor: 'inherit' });
    gsap.set('#plus', { opacity: '0' });

  }

  start() {

    if (this.state.modal === '') { window.scroll(0, 0); }

    this.setModal(this.state.modal);

  }

  setModal(val) {

    if (this.state.modal !== val) {

      this.setState({ modal: val }, () => {

        gsap.to('#modal', 0.05, { opacity: 1, display: 'block', onComplete: () => {

          disableBodyScroll(this.modal);
          this.setState({ scrolled: true });

          gsap.set('header.blend', { opacity: 1 });
          gsap.set('header.noBlend', { opacity: 0 });

        } });

      });

    } else {

      this.setState({ modal: '' }, () => {

        this.scroll();

        gsap.to('#modal', 0.05, { opacity: 0, display: 'none', onComplete: () => {

          enableBodyScroll(this.modal);

        } });

      });

    }

  }

  sendRequest(event) {

    event.preventDefault();

    if (this.checkSend() === true) {

      var form = new FormData();
      form.append('name', this.state.form.name);
      form.append('email', this.state.form.email);
      form.append('why', this.state.form.why);
      form.append('profession', this.state.form.profession);
      form.append('note', this.state.form.note);

      fetch("https://archivio.slamjam.com/contact/send.php", {
        method: 'POST', body: form
      }).then((result) => {

        var form = this.state.form;

        form.requested = true;

        this.setState({ form: form }, () => {

          setTimeout(() => {

            var form = {
              name: '',
              email: '',
              why: 'Voglio visitare l\'archivio',
              profession: '',
              note: '',
              sent: false,
              requested: false
            }

            this.setState({ form: form });

          }, 3000);

        });

        console.log(result);

      }, (error) => {

        console.log(error);

      });

    }

  }

  checkSend() {

    var form = this.state.form;

    if (form.name && this.validateEmail(form.email) && form.policy) { return true; }

    form.sent = true;

    this.setState({ form: form });

    return false;

  }

  onChangeInput(event) {

    var form = this.state.form;

    form[event.target.name] = event.target.value;

    this.setState({ form: form });

  }


  validateEmail(email) {

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());

  }

  acceptCookies() {

    var button = document.getElementById('rcc-confirm-button');

    if (button) { button.click(); }

  }

  screenshot(i) {

    var content = this.state.content;
    gsap.set('.item.drag', { cursor: 'wait' });
    gsap.set('section', { cursor: 'wait' });
    content.items[i].screenshot = true;

    this.setState({ content: content });

    var height = window.innerHeight;

    var itemsProcessed = [];
    var canvas = document.getElementById('canvas');
    var ctx = canvas.getContext('2d');
    var videos = document.querySelectorAll('video');
    var w, h;

    for (let i = 0, len = videos.length; i < len; i++) {

      var position = videos[i].getBoundingClientRect();

      const v = videos[i];
      if (!v.src) continue;

      if (position.top < height && position.bottom >= 0) {

        w = v.videoWidth;
        h = v.videoHeight;
        canvas.width = w;
        canvas.height = h;

        ctx.fillRect(0, 0, w, h);
        ctx.drawImage(v, 0, 0, w, h);

        itemsProcessed[itemsProcessed.length] = new Promise((resolve, reject) => {

          canvas.toBlob((blob) => {

            var url = URL.createObjectURL(blob);

            v.style.backgroundImage = `url(${url})`;
            v.style.backgroundSize = 'cover';

            resolve(url);

          },'image/png');

        });

      }

    }

    Promise.all(itemsProcessed).then(values => {

      if (window.innerWidth <= 425) { height = window.innerWidth*16/9; }

      html2canvas(document.body, {
        x: window.scrollX, y: window.scrollY,
        width: window.innerWidth, height: height,
        useCORS: true, logging: true
      }).then(canvas => {

        canvas.toBlob((blob) => {

          gsap.set('.item.drag', { cursor: 'grab' });
          gsap.set('section', { cursor: 'unset' });

          var link = document.createElement('a');

          link.href = URL.createObjectURL(blob);

          if (!isMobile) { link.download = 'ArchivioSlamJam_share.jpeg'; }

          link.click();

        },'image/jpeg');

      });

    });

  }

  download(i) {

    var content = this.state.content;
    content.items[i].pdf = true;

    this.setState({ content: content }, () => {

      setTimeout( () => { this.pdf[i].click(); }, 1000);

    });

  }

  shuffle(sourceArray) {

    for (var i = 0; i < sourceArray.length - 1; i++) {

      var j = i + Math.floor(Math.random() * (sourceArray.length - i));

      var temp = sourceArray[j];
      sourceArray[j] = sourceArray[i];
      sourceArray[i] = temp;

    }

    return sourceArray;

  }

  openNews(id) {

    this.setState({ newsOpened: id });

    gsap.timeline()
      .set('.overlayNewsItemContent', { height: 0 })
      .to("#news" + id, 1, { height: 'auto' });

    document.querySelectorAll('video').forEach(vid => vid.pause());

  }

  closeNews() {

    this.setState({ newsOpened: null });

    gsap.to('.overlayNewsItemContent', 1, { height: '0' });

    document.querySelectorAll('video').forEach(vid => vid.pause());

  }

  render() {

    const BlockContent = require('@sanity/block-content-to-react');

    Number.prototype.pad = function(size) {
      var s = String(this);
      while (s.length < (size || 2)) {s = "0" + s;}
      return s;
    }

    if (this.state.content) {

      var pdfIndex = 0;
      var pdfId = this.state.content.pdf;

      return (
        <div
          id="app"
          className={ (this.state.modal ? 'invert' : '') }
          onMouseMove={ (event) => { this.mouseMove(event) } }
        >

          <div id="plus" data-html2canvas-ignore></div>

          <div className="bar">
            <div className="displayFlex">

              <MarqueeText
                className="tick"
                text={ this.state.content.audio[this.state.audio].textTicker }
                repeat={ 4 }
              />

              <div className="player" onClick={ () => { this.toggleAudio() } }>

                <span className={ (this.state.isPlaying === true ? '' : 'displayNone') }>
                  Pause
                </span>
                <span className={ (this.state.isPlaying === true ? 'displayNone' : '') }>
                  Play
                </span>

              </div>

            </div>
          </div>

          <header className={ 'effect noBlend' }>
            <div className="positionRelative">

              <LazyImage
                className="logo"
                src={ Logo }
                onClick={ () => this.start() }
              />

            </div>
          </header>

          <header className={ 'effect blend' }>
            <div className="positionRelative">

              <LazyImage
                className="logo"
                src={ Logo }
                onClick={ () => this.start() }
              />

            </div>
          </header>

          <div className="header">
            <div className="positionRelative">

              <LazyImage className="logo" src={ LogoShadow } />

            </div>
          </div>

          <div className="menu">

            <a
              href="https://www.slamjam.com"
              className="item left"
              rel="noopener noreferrer"
              target="_blank"
            >Slam Jam</a>

            <div
              className="item right"
              onClick={ () => this.setModal('contact') }
            >Contact</div>

            <div
              className="item right"
              onClick={ () => this.setModal('manifest') }
            >Manifesto</div>

          </div>

          <CookieConsent data-html2canvas-ignore>
            To offer you a better experience, this sites uses profiling cookies, even from third parties.<br />
            By continuing to browse our site you accept our cookie policy. <a target="_blank" rel="noopener noreferrer" href="https://www.iubenda.com/privacy-policy/258167/cookie-policy">Find out more</a>. <span onClick={ () => { this.acceptCookies() } }>Yes</span>
          </CookieConsent>

          <main>

            <InfiniteScroll
              loadMore={ () => this.dataFetch(1) }
              hasMore={ ((this.state.rangeFetch[0] < this.randomStart && !this.state.hasFetch) || this.state.hasFetch) }
              threshold={ 500 }
              id="bounds"
            >

              {this.state.content.items.map((item, i) => {

                if (item.overlays.length > 0 || item.url) {
                  pdfId = this.state.content.pdf - pdfIndex; pdfIndex++;
                }

                return(
                      <section
                        key={ 'item' + i } id={ 'section' + i }
                        style={{ height: ((100*item.height)/item.width).toFixed(2) + 'vw' }}
                      >

                        { item.video ? (
                          <video
                            src={ item.video.url }
                            autoPlay loop muted
                            playsInline crossOrigin={ 'anonymous' }
                          />
                        ) : (
                          <LazyBackground
                            className="background"
                            src={ item.image.url + '?auto=format&q=60' }
                          />
                        ) }

                        <div className="text">
                          { item.id } { item.text }
                        </div>

                        <div
                          className={ 'overlay' + (item.ended || (item.overlays.length === 0 && !item.url) ? ' pointerEventsNone': '') }
                          onMouseEnter={ () => { this.enterCursor() } }
                          onMouseLeave={ () => { this.leaveCursor() } }
                          onClick={ () => { this.showOverlay(i); } }
                          data-html2canvas-ignore
                        ></div>

                        {!item.overlays.length > 0 ? null : (
                          <>
                            {item.overlays.map((item2, ii) => {
                              if (isMobile) {
                                var widthMobile = (item2.widthMobile ? parseInt(item2.width)+(parseInt(item2.width)*item2.widthMobile/100) : parseInt(item2.width)+(parseInt(item2.width)*70/100));
                                var xMobile = (item2.xMobile ? item2.xMobile : item2.x);
                                var yMobile = (item2.yMobile ? item2.yMobile : item2.y);
                              }
                              return(
                                <React.Fragment key={ 'image' + i + '_' + ii }>
                                  <BrowserView>
                                    <div
                                      id={ 'image' + i + '_' + ii }
                                      className={ 'item drag' + (item2.visible ? ' visible': '') }
                                      style={ {
                                        zIndex: parseInt(ii)+5,
                                        width: item2.width === 'auto' ? '' : item2.width + '%',
                                        top: item2.y + '%', left: item2.x + '%'
                                      } }
                                    >

                                      { item2.video ? (
                                        <video
                                          src={ item2.video.url }
                                          autoPlay loop muted playsInline
                                          crossOrigin={ 'anonymous' }
                                        />
                                      ) : (
                                        <img
                                          src={ item2.image.url + '?auto=format&q=60' }
                                          style={ { width: item2.width === 'auto' ? 'auto' : '100%' } }
                                          alt=""
                                        />
                                      ) }

                                      <div
                                        className={ 'text' + (item2.textColor === 'black' ? ' black' : '') }
                                        style={ {
                                          paddingBottom: item2.textPaddingVertical + '%',
                                          paddingLeft: item2.textPaddingHorizontal + '%',
                                          paddingRight: item2.textPaddingHorizontal + '%'
                                        } }
                                      >
                                        { item2.id } { item2.text }
                                      </div>
                                    </div>
                                  </BrowserView>
                                  <MobileView>
                                    <div
                                      id={ 'image' + i + '_' + ii }
                                      className={ 'item drag' + (item2.visible ? ' visible': '') }
                                      style={ {
                                        zIndex: parseInt(ii)+5,
                                        width: item2.width === 'auto' ? '' : widthMobile + '%',
                                        top: yMobile + '%', left: xMobile + '%'
                                      } }
                                    >

                                      { item2.video ? (
                                        <video
                                          src={ item2.video.url }
                                          autoPlay loop muted playsInline
                                          crossOrigin={ 'anonymous' }
                                        />
                                      ) : (
                                        <img
                                          src={ item2.image.url + '?auto=format&q=60' }
                                          alt=""
                                        />
                                      ) }

                                      <div
                                        className={ 'text' + (item2.textColor === 'black' ? ' black' : '') }
                                        style={ {
                                          paddingBottom: item2.textPaddingVertical + '%',
                                          paddingLeft: item2.textPaddingHorizontal + '%',
                                          paddingRight: item2.textPaddingHorizontal + '%'
                                        } }
                                      >
                                        { item2.id } { item2.text }
                                      </div>
                                    </div>
                                  </MobileView>
                                </React.Fragment>
                              )
                            })}
                          </>
                        )}

                        { !item.url ? null : (
                          <a
                            href={ item.url }
                            rel="noopener noreferrer"
                            target="_blank"
                            className={ (item.visibleURL ? 'visible': '') }
                          >
                            { item.url }
                          </a>
                        ) }

                        <div
                          style={{ zIndex: item.overlays.length+6 }}
                          className={ 'callToAction' + (item.visibleCTA ? ' visible': '')}
                          data-html2canvas-ignore
                        >

                          <div
                            className={ 'download' + (item.pdf ? ' opacity0 pointerEventsNone' : '') }
                            onClick={ () => this.download(i) }
                          ></div>

                          <div
                            className={ 'screenshot' + (item.screenshot ? ' opacity0 pointerEventsNone' : '') }
                            onClick={ () => this.screenshot(i) }
                          ></div>

                        </div>

                        <div>

                          {item.pdf && (
                            <PDFDownloadLink
                              document={<Pdf item={ item } id={ (pdfId).pad(3) } />}
                              fileName={ 'ArchivioSlamJam_' + (pdfId).pad(3) + '.pdf' }
                              data-html2canvas-ignore
                            >
                              <div ref={ (input) => { this.pdf[i] = input } }></div>
                            </PDFDownloadLink>
                          )}

                        </div>

                      </section>

                )

              })}

            </InfiniteScroll>

          </main>

          { this.state.content.news.length && (
            <div
              className="overlayNews"
              onClick={ (e) => {
                if (e.target === e.currentTarget) {
                  gsap.set(".overlayNews", {
                    opacity: 0,
                    pointerEvents: 'none'
                  });
                  document.querySelectorAll('video').forEach(vid => vid.pause());
                }
              } }
            >
              <div>
                { this.state.content.news.map((item, i) => {
                  return(
                    <div className="overlayNewsItem">

                      <div
                        onClick={ () => this.state.newsOpened === i ? this.closeNews() : this.openNews(i) }
                        className="overlayNewsItemTitle"
                      >
                          { item.title }
                      </div>

                      <div id={ "news" + i } className="overlayNewsItemContent">
                        <div>
                          { item.content.map((item, i) => {
                            return(
                              <>

                                { (item.file && item._type === 'file') && (
                                  <div className="overlayNewsItemVideo">
                                    <video controls src={ item.file } />
                                  </div>
                                ) }

                                { (item.file && item._type === 'image') && (
                                  <div className="overlayNewsItemImage">
                                    <img src={ item.file } />
                                  </div>
                                ) }

                                { item.text && (
                                  <div className="overlayNewsItemText">
                                    <BlockContent blocks={ item.text } />
                                  </div>
                                ) }

                              </>
                            )
                          }) }
                        </div>
                      </div>

                    </div>
                  )
                }) }
                <div
                  className="overlayNewsClose"
                  onClick={ (e) => {
                    if (e.target === e.currentTarget) {
                      gsap.set(".overlayNews", {
                        opacity: 0,
                        pointerEvents: 'none'
                      })
                    }
                  } }
                >close</div>
              </div>
            </div>
          ) }

          <audio
            id="audio"
            src={ this.state.content.audio[this.state.audio].url }
            data-html2canvas-ignore
          />

          <canvas id="canvas"></canvas>

          <div
            id="modal"
            ref={ (ref) => { this.modal = ref } }
            className={ this.state.modal }
            data-html2canvas-ignore
          >

            { this.state.modal === 'manifest' ?
              <BlockContent blocks={ this.state.content.manifest } />
            : null }

            { this.state.modal === 'contact' ? (
              <>

                <BlockContent blocks={ this.state.content.contact } />

                <div className="form">
                  <form onSubmit={ (event) => this.sendRequest(event) } noValidate>

                    <label>Name*</label>
                    <input
                      className={ (this.state.form.sent === true && !this.state.form.name ? 'red' : '') }
                      type="text" name="name"
                      onChange={ (event) => this.onChangeInput(event) }
                      value={ this.state.form.name }
                    />

                    <label>Email*</label>
                    <input
                      className={ (this.state.form.sent === true && !this.validateEmail(this.state.form.email) ? 'red' : '') }
                      type="text" name="email"
                      onChange={ (event) => this.onChangeInput(event) }
                      value={ this.state.form.email }
                    />

                    {/*
                      <label>Per cosa ci stai contattando?</label>
                      <select
                        name="why"
                        onChange={ (event) => this.onChangeInput(event) }
                        value={ this.state.form.why }
                      >
                        <option>Voglio visitare l'archivio</option>
                        <option>Voglio accedere all'archivio online</option>
                      </select>
                    */ }

                    <label>Profession</label>
                    <input
                      type="text" name="profession"
                      onChange={ (event) => this.onChangeInput(event) }
                      value={ this.state.form.profession }
                    />

                    <label>Message</label>
                    <TextareaAutosize
                      name="note" minRows="4"
                      onChange={ (event) => this.onChangeInput(event) }
                      value={ this.state.form.note }
                    />

                    <div className="policy">
                      <input
                        className={ (this.state.form.sent === true && !this.state.form.policy ? 'red' : '') }
                        type="checkbox" name="policy" id="policy"
                        onChange={ (event) => this.onChangeInput(event) }
                        value={ this.state.form.policy }
                      />
                      <label for="policy">I authorize the processing of my personal data (<a target="_blank" rel="noopener noreferrer" href="https://www.iubenda.com/privacy-policy/258167">Privacy policy</a>)*</label>
                    </div>

                    { !this.state.form.requested ? (
                      <input type="submit" value="Send" />
                    ) : (
                      <input type="submit" value="Your message as been sent" disabled />
                    ) }

                  </form>
                </div>

              </>
            ) : null }

          </div>

        </div>
      )

    } else {

      return(
        <div id="app"></div>
      )

    }

  }
}

export default App;
