import React from 'react';
import gsap from "gsap";

class LazyImage extends React.Component {

  state = { src: null };

  componentDidMount() {

    const imageLoader = new Image();

    imageLoader.onload = () => {

      this.setState({ src: this.props.src }, () => {

        gsap.to('img.' + this.props.className.replace(/ /g, "."), 0.05, { opacity: 1 });

      });

    };

    imageLoader.src = this.props.src;

  }

  render() {

    return (
      <>

        { !this.state.src ? null : (
          <img
            { ...this.props }
            className={ this.props.className }
            src={ this.state.src }
            alt=""
          />
        ) }

      </>
    )

  }

}

export default LazyImage;
